// REACT DEPENDENCIES
import React, {useState} from "react";

// REDUX DEPENDENCIES
import store from "../store";
import {setDetails} from "../reducers/userSlice";

// REACT ROUTER DEPENDENCIES
import { useNavigate } from "react-router-dom";

//SERVICES
import AuthenticationService from "../services/AuthenticationService";

// MUI DEPENDENCIES
import Button from '@mui/material/Button';
import FormControl from "@mui/material/FormControl";
import Grid from '@mui/material/Grid2';
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Item from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';

// ICONS
import PersonIcon from '@mui/icons-material/Person';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Login = () => {
    const [username, setUsername] = useState(''),
          [password, setPassword] = useState(''),
          [showPassword, setShowPassword] = useState(false),
           authService = new AuthenticationService(),
           navigate = useNavigate();

    const login = async ()=>{
        const response = await authService.login(username, password);

        console.log(response.data);

        store.dispatch(setDetails(response.data));

        navigate('dashboard');
    };

    return (<div>
        <Grid container
              rowSpacing={2}
              columnSpacing={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="80vh">
            <Grid size={{xs: 1, lg: 4}}></Grid>
            <Grid size={{xs: 10, lg: 4}} style={{background: "white"}}>
                <Grid container spacing={2}>
                    <Grid size={{xs: 12, lg: 12}}>
                        <form>
                            <Item style={{"textAlign": "center"}}>Login</Item>
                            <Item></Item>
                            <Item>
                                <FormControl fullWidth variant="outlined" error={false}>
                                <TextField
                                    id="username"
                                    label="Username"
                                    variant="outlined"
                                    type="text"
                                    autoComplete="username"
                                    style={{width: "100%"}}
                                    slotProps={{input: {
                                            endAdornment: <InputAdornment position="end"> <PersonIcon /> </InputAdornment>
                                        }
                                    }}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                                </FormControl>
                            </Item>
                            <Item>
                                <FormControl fullWidth variant="outlined" error={false}>
                                    <InputLabel htmlFor="password">Password</InputLabel>
                                    <OutlinedInput
                                        id="password"
                                        autoComplete="current-password"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        onChange={(event) => {setPassword(event.target.value)}}
                                        endAdornment={
                                            <InputAdornment style={{cursor: "pointer"}} position="end" onClick={()=>{ setShowPassword(!showPassword)}}>
                                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                            </InputAdornment>
                                        }
                                        label="Password"
                                    />
                                </FormControl>
                            </Item>
                            <Item></Item>
                            <Item> <Button variant="contained" color="primary" style={{width: "100%"}}
                                           size={"large"} onClick={login}>Login</Button> </Item>
                            <Item></Item>
                            <Item> Forgot Password </Item>
                        </form>
                    </Grid>
                </Grid>
            </Grid>
            <Grid size={{xs: 1, lg: 4}}></Grid>
        </Grid>


    </div>);
};

export default Login;