// DEPENDENCIES
import React from 'react';

// ROUTES
import ApplicationRoutes from "./ApplicationRoutes";
import ErrorRoutes from "./ErrorRoutes";
import LoginRoutes from "./LoginRoutes";

const Routes =[...LoginRoutes, ...ApplicationRoutes, ...ErrorRoutes];

export default Routes;
