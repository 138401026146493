import React from 'react';
import Login from './../views/Login';
import {Outlet} from "react-router-dom";

const LoginRoutes = [
    {
        path: "/login",
        element: <Login/>,
    },
    // {
    //     path: '/change/password',
    //     element: <ChangePassword/>,
    // },
    // {
    //     path: '/change/password/:token',
    //     element: <ChangePassword/>
    // }
];

export default LoginRoutes;
