import React from 'react';

const NotFound = React.lazy(() => import("../views/NotFound"));

const ErrorRoutes = [
    {
        path: "*",
        element: <NotFound/>
    }
];

export default ErrorRoutes;
