import axios from "axios";

export default class RequestService {
    constructor() {
        const configuration = {
            baseURL: `${window.environment.API_PROTOCOL}://${window.environment.API_HOSTNAME}/${window.environment.API_VERSION}/`,
            timeout: 25000,
        };

        const token = localStorage.getItem('bt');
        // const token = null;

        if (token) {
            configuration['headers'] = {'Authorization': `${token}`};
        }

        this.request = axios.create(configuration);

        this.request.interceptors.response.use(function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        }, function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            const status = error.request.status;

            if (status === 403) {
                window.location = `${window.environment.API_PROTOCOL}://${window.environment.API_HOSTNAME}/${window.environment.APPLICATION_BASENAME}`;
            } else {
                return Promise.reject(error);
            }

        });
    }
}
