import React from 'react';

import {
    Outlet,
    redirect
} from "react-router-dom";

import CMS from '../apps/CMS';
import Login from "../views/Login";
import Dashboard from "../views/Dashboard";

const ApplicationRoutes = [{
    path: '/',
    element: <CMS/>,
    children: [
        {
            path: "/",
            element: <Login />,
        },
        {
            path: "/dashboard",
            element: <Dashboard />,
        },
    ],
}];

export default ApplicationRoutes;
