import React, {Suspense} from "react";
import {QueryClient, QueryClientProvider, useQuery, useMutation} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';

import CssBaseline from '@mui/material/CssBaseline';

import {createTheme, ThemeProvider, styled} from '@mui/material/styles';

import {Outlet} from 'react-router-dom';

import {Box, Container, Grid} from '@mui/material';

const defaultTheme = createTheme();

const lightTheme = createTheme({
    typography: {
        fontFamily: [
            'Inter',
            'Roboto',
        ].join(','),
        fontSize: 14
    },
    components: {
        MuiGrid2: {
            defaultProps: {
                padding: "4px"
            }
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true
            },
        },
        MuiAppBar: {
            defaultProps: {
                elevation: 0,
            }
        },
        MuiToolbar: {
            defaultProps: {
                elevation: 0,
                height: "97px"
            }
        },
        MuiTabs: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    ".MuiTab-root": {
                        color: "#64738A",
                    }
                },
            },
        }
    },
    text: {
        primary: "#000",
        size: "10px"
    },
    palette: {
        background: {default: "#f3f3f3", light: "#f3f3f3", dark: "#f3f3f3"},
        primary: {main: "#00396a"},
        secondary: {main: "#006EB2"},
        blue: {main: "#006EB2"},
        white: {main: "#FFF"},
    },
    zIndex: {
        appBar: 1500
    }
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        }
    }
});

import {useSelector} from 'react-redux';
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";

const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.only("xs")]: {
            marginLeft: "65px"
        },
        [theme.breakpoints.only("sm")]: {
            marginLeft: "65px"
        },
        [theme.breakpoints.only("md")]: {
            marginLeft: "65px"
        },
        [theme.breakpoints.only("lg")]: {
            marginLeft: "65px"
        }, [theme.breakpoints.only("xl")]: {
            marginLeft: "65px"
        },
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            [theme.breakpoints.only("xs")]: {
                marginLeft: "240px"
            },
            [theme.breakpoints.only("sm")]: {
                marginLeft: "200px"
            },
            [theme.breakpoints.only("md")]: {
                marginLeft: "200px"
            },
            [theme.breakpoints.only("lg")]: {
                marginLeft: "200px"
            }, [theme.breakpoints.only("xl")]: {
                marginLeft: "200px"
            }

        }),
    }),
);

export default function CMS() {

    return <>
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false}/>
            <ThemeProvider theme={lightTheme}>
                <CssBaseline>
                    <Box component="div">
                        <Outlet/>
                    </Box>
                </CssBaseline>
            </ThemeProvider>
        </QueryClientProvider>
    </>;
};
