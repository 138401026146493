import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        details: {
            firstName: "",
            lastName: "",
            message : ""
        },
        permissions: [],
        token: null
    },
    reducers: {
        setDetails: (state, details) => {
            state.details = details.payload;
        },
        setPermissions: (state, permissions) => {
            state.permissions = permissions.payload;
        },
        setToken: (state, token) => {
            state.token = token.payload;
        }

    },
})

export const { setApplications, setDetails, setPermissions, setToken } = userSlice.actions;

// MODEL SELECTOR
export const selectUser = (state) => state.user;

export default userSlice.reducer;
