// REACT DEPENDENCIES
import React, {Suspense} from "react";
import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client';

import {
    createBrowserRouter,
    RouterProvider, useNavigate, useRouteError
} from "react-router-dom";

// APPLICATIONS
import CMS from "./apps/CMS";

// ROUTES
import Routes from './routes/Routes';

// FONTS
import "@fontsource/inter/100.css";
import "@fontsource/inter/200.css";
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import "@fontsource/inter/900.css";

// STYLES

// REDUX
import store from "./store";
import {Provider, useSelector} from "react-redux";

const container = document.getElementById('app'),
    root = createRoot(container),
    browserRouter = createBrowserRouter(Routes, {basename: '/'});

root.render(
        <Provider store={store}>
            <Suspense fallback={<></>}>
                <RouterProvider router={browserRouter}>
                    <CMS/>
                </RouterProvider>
            </Suspense>
        </Provider>);
