import RequestService from "./RequestService";

export default class AuthenticationService extends RequestService {
    constructor(...params) {
        super(...params);
    }

    async login(username, password) {
        return await this.request.post('/auth/login', {username, password});
    }
}
