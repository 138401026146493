// REACT DEPENDENCIES
import React from 'react';

import {useSelector} from 'react-redux';

import TopBar from "../components/TopBar";

const Dashboard = () => {
    const {firstName, lastName, message} = useSelector((state) => {
       return state.user.details;
    });

    console.log('details', firstName, lastName , message);

    return (<div>
        <TopBar></TopBar>
    </div>);
}


export default Dashboard;